import * as React from 'react';
import { differenceInYears, isWithinInterval } from 'date-fns';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import styles from './PaxCard.styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import IPax from '../types/IPax';
import { isNil } from 'lodash';
import { EMPTY_FIELD_TEXT } from 'common/constants/constants';
import formatDate from 'common/helpers/formatDate';

interface IState {
  expanded: boolean;
}

interface IProps extends WithStyles<typeof styles> {
  pax: IPax;
  departureStartDate: Date;
  departureEndDate: Date;
}

class PaxCard extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  public render() {
    const { pax, departureStartDate, departureEndDate, classes } = this.props;
    const dietaryNotes = pax.dietaryNotes || '';
    const packageDepartureCode = pax.packageDepartureCode || '';
    const medicalNotes = pax.medicalNotes || '';
    const leaderNotes = pax.leaderNotes ? pax.leaderNotes.join('\r\n') : '';
    const workPhone = pax.travelContact.workPhone || '';
    const mobilePhone = pax.travelContact.mobilePhone || '';
    const homePhone = pax.travelContact.homePhone || '';

    const fullName = (
      <Typography
        variant="body2"
        id={'pax.header.fullName_' + pax.id}
        className={classes.typography}
      >
        {pax.fullNameWithTitle}
      </Typography>
    );

    const isBirthdayOnTrip = this.checkForBirthdayOnTrip(
      pax.dateOfBirth,
      departureStartDate,
      departureEndDate
    );

    return (
      <Card>
        <CardHeader
          title={fullName}
          subheader={this.createSubHeader(
            pax.id,
            pax.bookingId,
            pax.singleSupplementBooked,
            pax.dateOfFirstTravel,
            pax.dateOfBirth,
            pax.passport.nationality,
            dietaryNotes,
            packageDepartureCode,
            medicalNotes,
            leaderNotes,
            isBirthdayOnTrip,
            classes,
            workPhone,
            mobilePhone,
            homePhone
          )}
          onClick={() =>
            this.handleExpandClick(
              this.isExpandable(
                pax.singleSupplementBooked,
                dietaryNotes,
                packageDepartureCode,
                medicalNotes,
                leaderNotes,
                isBirthdayOnTrip,
                workPhone,
                mobilePhone,
                homePhone
              )
            )
          }
          id={'pax.header_' + pax.id}
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {pax.singleSupplementBooked &&
              this.createSection(
                'Single Supplement',
                'Booked a single supplement',
                'pax.content.singleSupplementBooked_' + pax.id
              )}
            {dietaryNotes &&
              this.createSection(
                'Dietary Notes',
                dietaryNotes,
                'pax.content.dietaryNotes_' + pax.id
              )}
            {packageDepartureCode &&
              this.createSection(
                'Combination Trip',
                packageDepartureCode,
                'pax.content.packageDepartureCode_' + pax.id
              )}
            {medicalNotes &&
              this.createSection(
                'Medical Notes',
                medicalNotes,
                'pax.content.medicalNotes_' + pax.id
              )}
            {leaderNotes &&
              this.createSection(
                'Additional Information',
                leaderNotes,
                'pax.content.leaderNotes_' + pax.id
              )}
            {isBirthdayOnTrip &&
              pax.dateOfBirth &&
              this.createSection(
                'Birthday on Trip',
                formatDate(pax.dateOfBirth, process.env.REACT_APP_DATE_FORMAT),
                'pax.content.leaderNotes_' + pax.id
              )}
            {mobilePhone &&
              this.createSection(
                'Mobile Phone Number',
                mobilePhone,
                'pax.content.mobileNumber_' + pax.id
              )}
            {homePhone &&
              this.createSection(
                'Home Phone Number',
                homePhone,
                'pax.content.homeNumber_' + pax.id
              )}
            {workPhone &&
              this.createSection(
                'Work Phone Number',
                workPhone,
                'pax.content.workPhone_' + pax.id
              )}
          </CardContent>
        </Collapse>
      </Card>
    );
  }

  private isExpandable = (
    singleSupplementBooked,
    dietaryNotes,
    packageDepartureCode,
    medicalNotes,
    leaderNotes,
    isBirthdayOnTrip,
    workPhone,
    mobilePhone,
    homePhone
  ) => {
    if (
      singleSupplementBooked ||
      dietaryNotes ||
      packageDepartureCode ||
      medicalNotes ||
      leaderNotes ||
      isBirthdayOnTrip ||
      workPhone ||
      mobilePhone ||
      homePhone
    ) {
      return true;
    }

    return false;
  };

  private getAgeLabel = (dateOfBirth, dateOfFirstTravel) => {
    return !isNil(dateOfBirth)
      ? `${differenceInYears(dateOfFirstTravel, dateOfBirth!)} yrs`
      : EMPTY_FIELD_TEXT;
  };

  private checkForBirthdayOnTrip(dateOfBirth, startDate, endDate) {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();

    return (
      isWithinInterval(new Date(dateOfBirth).setFullYear(startYear), {
        start: new Date(startDate),
        end: new Date(endDate)
      }) ||
      isWithinInterval(new Date(dateOfBirth).setFullYear(endYear), {
        start: new Date(startDate),
        end: new Date(endDate)
      })
    );
  }

  private createSubHeader = (
    paxId,
    bookingId,
    singleSupplementBooked,
    dateOfFirstTravel,
    dateOfBirth,
    nationality,
    dietaryNotes,
    packageDepartureCode,
    medicalNotes,
    leaderNotes,
    isBirthdayOnTrip,
    classes,
    workPhone,
    mobilePhone,
    homePhone
  ) => (
    <div>
      <span id={'pax.header.booking_' + paxId}>{bookingId}</span> •
      <span id={'pax.header.age_' + paxId}>
        {' '}
        {this.getAgeLabel(dateOfBirth, dateOfFirstTravel)}
      </span>
      {singleSupplementBooked && (
        <Icon color="secondary" className={classes.icon}>
          person
        </Icon>
      )}
      {dietaryNotes && (
        <Icon color="secondary" className={classes.icon}>
          local_dining
        </Icon>
      )}
      {packageDepartureCode && (
        <Icon color="secondary" className={classes.icon}>
          link
        </Icon>
      )}
      {medicalNotes && (
        <Icon color="secondary" className={classes.icon}>
          local_hospital
        </Icon>
      )}
      {leaderNotes && (
        <Icon color="secondary" className={classes.icon}>
          chat
        </Icon>
      )}
      {isBirthdayOnTrip && (
        <Icon color="secondary" className={classes.icon}>
          cake
        </Icon>
      )}
      {(workPhone || homePhone || mobilePhone) && (
        <Icon color="secondary" className={classes.icon}>
          phone
        </Icon>
      )}

      <Typography
        variant="body2"
        color="inherit"
        className={classes.subHeaderText}
        noWrap
      >
        <span id={'pax.header.nationality_' + paxId}> {nationality}</span>
      </Typography>
    </div>
  );

  private createSection = (key, value, sectionId) => {
    return (
      <div>
        <Typography
          variant="caption"
          className={this.props.classes.typography}
          color="textSecondary"
        >
          {key}
        </Typography>
        <Typography
          variant="body1"
          paragraph={true}
          style={{ whiteSpace: 'pre-line' }}
          className={this.props.classes.typography}
        >
          <span id={sectionId}>{value}</span>
        </Typography>
      </div>
    );
  };

  private handleExpandClick = expandable => {
    this.setState({ expanded: expandable && !this.state.expanded });
  };
}

export default withStyles(styles)(PaxCard);
